import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QUERY_KEYS } from '@api'

const useOnDeleteSuccess = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.PREDICTIONS)
    notification.success({
      message: t('predictions.notifications.delete_success'),
    })
  }


  return { onDeleteSuccess }
}

export { useOnDeleteSuccess }
