export const QUERY_KEYS = {
  BACKOFFICE_USER: 'backofficeUser',
  USERS: 'allUsers',
  USER_BY_ID: 'userById',
  USER_UPDATE: 'updatedUser',
  USER_DELETE: 'deletedUser',
  PASSWORD_UPDATE: 'updatePassword',
  CREATE_USER: 'userCreate',
  SEND_REPORT: 'sendReport',
  UPDATE_USER_PASSWORD: 'userPasswordUpdate',
  VERIFY_USER: 'userVerify',
  COUPONS: 'coupons',
  FILTERED_COUPONS: 'couponsFiltered',
  UPDATE_COUPONS: 'couponsUpdate',
  CREATE_COUPONS: 'couponsCreate',
  DELETE_COUPONS: 'couponsDelete',
  CONTRACTS: 'allContracts',
  REVIEW_ITEMS: 'allReviewItems',
  REVIEW_ITEMS_VALIDATE: 'ReviewItemsValidate',
  REVIEW_ITEMS_REJECT: 'ReviewItemsReject',
  QUIZZES: 'allQuizzes',
  QUIZZ_BY_ID: 'quizzById',
  QUESTION_BY_ID: 'questionById',
  DELETE_QUIZZ: 'quizzDelete',
  CREATE_QUIZZ: 'quizzCreate',
  QUIZZ_UPDATE: 'quizzUpdate',
  CREATE_ANSWER: 'answerCreate',
  UPDATE_ANSWER: 'answerUpdate',
  CREATE_QUESTION: 'questionCreate',
  UPDATE_QUESTION: 'questionUpdate',
  DELETE_QUESTION: 'questionDelete',
  DELETE_ANSWER: 'answerDelete',
  COLLECTIBLES_WITHOUT_OWNER: 'collectiblesWithoutOwner',
  CLIENTS: 'clients',
  TEAMS: 'teams',
  BRANDS: 'brands',
  CHALLENGES: 'allChallenges',
  CHALLENGE: 'challenge',
  PREDICTIONS: 'predictions',
  PREDICTIONS_MATCHES: 'predictionsMatches',
  MEDIA: 'media',
  WIDGETS: 'widgets',
  WIDGET: 'widget',
}
