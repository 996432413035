import { useQuery } from "react-query"

import { QUERY_KEYS } from "@api"

import { PredictionsApi } from "../api"


export const useMatches = (categoryId: string) => {

    
    const getNextMatches = async (categoryId: string) => {
        const currentRound = await PredictionsApi.getCurrentRound(categoryId)
        const matchesCurrentRound = await PredictionsApi.getNextMatches(categoryId, currentRound)
        const matchesNextRound = await PredictionsApi.getNextMatches(categoryId, (parseInt(currentRound) + 1).toString())

        return [...matchesCurrentRound.match, ...matchesNextRound.match]
    }

    const { data: matches, isLoading: isLoadingMatches } = useQuery({
        queryKey: [QUERY_KEYS.PREDICTIONS_MATCHES],
        queryFn: async () => getNextMatches(categoryId),
        keepPreviousData: true,
        staleTime: 5000,
      })


      return {
        matches,
        isLoadingMatches,

    }
}