import { api } from "@api"

import { CompetitionsResponse, MatchesResponse } from "../types"


const getCurrentRound = async (categoryId: string) => {
    //console.log(page)
    const response = await api.get<CompetitionsResponse>(`/predictions/competitions/my`)

    //console.log(response.data)
        
        //get category with id
        const category = response.data.category.find((category) => category.id === categoryId)
        
        if(!category){
            throw new Error("Category not found")
            
        }

        return category.current_round
}

const createPrediction = async (matchId: string) => {
    //console.log(page)
    const response = await api.post(`/predictions/`, {
        partnerId: 1,
        matchId,
    })
           
    return response.data
}

const getNextMatches = async (categoryId: string, currentRound: string) => {

    ///admin/predictions/matches?league=37&round=32
    const response = await api.get<MatchesResponse>(`/predictions/matchesAndPredictions?league=${categoryId}&round=${currentRound}`)
  
    //console.log({response})

    return response.data
}

const getPredictions = async (page:number) => {
    //console.log(page)
    const response = await api.get(`/predictions?page=${page}`)

    //console.log(response.data)
        
    return response.data
}

const deletePrediction = async (predictionId: number) => {
    //console.log(page)
    const response = await api.delete(`/predictions/${predictionId}`)

    //console.log(response.data)
        
    return response.data
}

export const PredictionsApi = {
    getCurrentRound,
    getNextMatches, 
    createPrediction, 
    getPredictions, 
    deletePrediction
}