import { Button, Col, Modal, Row, Table, Tooltip } from "antd"
import { useTranslation } from "react-i18next"

import { StyledAlignedTitleRow, StyledTitle } from "../../components"
import { CATEGORY_ID } from "../../constants"
import { useMatches } from "../../hooks/useMatches"
import { usePredictions } from "../../hooks/usePredictions"




interface NextMatchesModalProps {
    visible: boolean
    onClose: () => void
}

export const NextMatchesModal = (props: NextMatchesModalProps) => {
    
    const { visible, onClose } = props
    const { matches, isLoadingMatches } = useMatches(CATEGORY_ID)
    const { createPrediction } = usePredictions()

    // const [matchesWithPredictions, setMatchesWithPredictions] = useState<MatchDto[]>(matches??[])
    

    // useEffect(() => {

    //     //console.log(predictions?.data)

        
    //     if (matches && predictions) {
    //         const aux = matches.map((match) => {
                
    //             return {
    //                 ...match,
    //             }
    //         })

    //         setMatchesWithPredictions(aux)

    //     }


    // }, [matches, predictions])

    const { t } = useTranslation()

    const columns = [
        {
          title: t('predictions.modals.next_matches_modal.table_headers.id'),
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.match'),          
          key: 'match',
          render: (text, record) => (
            <div>{record.local} vs {record.visitor}</div>
          ),
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.round'),
          dataIndex: 'round',
          key: 'round',
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.schedule'),
          key: 'schedule',
          render: (text, record) => (
            <div>{new Date(record.schedule).toLocaleString('es-ES')}</div>
          ),
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.status'),
          key: 'status',
          render: (text, record) => (
            <span>
              {record.hasPrediction ? 'Con prediccion' : 'Sin prediccion'}
            </span>  
            )
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.actions'),
          key: 'actions',
          render: (text, record) => (
            <div>
              <Tooltip title={t('predictions.buttons.tooltips.create_prediction')}>
                <Button 
                    type='primary' 
                    onClick={() => createPrediction(record.id)}
                    disabled={record.hasPrediction || new Date(record.schedule) < new Date()}
                    >
                  {t('predictions.buttons.create_prediction')}
                </Button>
              </Tooltip>
            </div>
          ),
        },
    ]


    {if (isLoadingMatches && !matches && visible) return <div>Loading...</div>}

    return (

        <Modal
          width={1000}
          open={visible}
          title={t('predictions.modals.next_matches_modal.title')}
          onCancel={onClose}
          transitionName='ant-fade'
          focusTriggerAfterClose={false}
          maskClosable={false}
          closable={false}

          footer={[
              <Button key='cancel' onClick={onClose}>
                  {t('predictions.buttons.cancel')}
              </Button>,

              // <Button
              //     type='primary'
              //     key='delete'
              //     onClick={() => {
              //         //form.submit()
              //     }}
              // >
              // {t('challenges.modals.metadata.save')}
              // </Button>,
        ]}
    >
    <Row>
        <Col span={24} style={{ marginBottom: '2%' }}>
        <Col span={24}>
            <StyledAlignedTitleRow>
            <Col style={{ marginRight: '1%' }}>
                <StyledTitle>{t('predictions.modals.next_matches_modal.title')}</StyledTitle>
            </Col>
            </StyledAlignedTitleRow>
        </Col>
        <Col span={24}>
            <Table

            // loading={isLoading}
            columns={columns}

            dataSource={matches}
            rowKey='id'
            pagination={{
                
                position: ['bottomCenter'],

                // total: (predictions?.total || 0),
                // current: (predictions?.page || 0)+1,
                // onChange: (page) => {
                // moveToPage(page)
                // }
            }}
            />
        </Col>
        </Col>
    </Row>
    </Modal>





  )
}